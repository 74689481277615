import {
  Box,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Spinner,
  Stack
} from '@qcx/ui';
import { Suspense, lazy, useCallback } from 'react';
import { useAuthModalStore } from './auth-modal.store';

const AuthOutlet = lazy(() =>
  import('./AuthOutlet').then(mod => ({ default: mod.AuthOutlet }))
);

interface AuthModalProps {
  link?: string;
  bannerSrc: string;
  bannerAlt: string;
  signupTitle?: string;
  onOpenChange?: (open: boolean) => void;
}

export const AuthModal = ({
  signupTitle = 'Cadastre-se',
  link,
  bannerSrc,
  bannerAlt,
  onOpenChange
}: AuthModalProps) => {
  const isOpen = useAuthModalStore(s => s.isOpen);
  const close = useAuthModalStore(s => s.close);

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (onOpenChange) {
        onOpenChange(open);
      }

      if (!open) {
        close();
      }
    },
    [close, onOpenChange]
  );

  return (
    <Modal open={isOpen} onOpenChange={handleOpenChange}>
      <ModalContent
        css={{
          maxH: 'fit-content',
          borderRadius: '$0',
          '@xs': {
            borderRadius: '20px 20px 0 0',
            width: '100%',
            height: '100%',
            bottom: 0
          },
          '@lg': {
            maxW: 800,
            p: '$0 !important'
          }
        }}
      >
        <ModalBody>
          <Stack flow={{ '@initial': 'column', '@lg': 'row' }} gap="$6">
            <Box
              as="a"
              css={{
                display: 'none',
                flex: 1,
                '@lg': {
                  display: 'flex',
                  w: '$7of12'
                }
              }}
              href={link || '#'}
            >
              <Image
                css={{
                  w: '$full',
                  img: {
                    w: '$full',
                    h: '$full'
                  }
                }}
                width={384}
                height={576}
                alt={bannerAlt}
                src={bannerSrc}
              />
            </Box>
            <Box
              css={{
                flex: 1,
                '@lg': {
                  w: '$5of12',
                  pt: '$6',
                  pb: '$6',
                  pr: '$6'
                }
              }}
            >
              <Suspense
                fallback={
                  <Box
                    css={{
                      minMaxSize: '$full',
                      flexCenter: true,
                      fontSize: '$4xl'
                    }}
                  >
                    <Spinner />
                  </Box>
                }
              >
                {isOpen && <AuthOutlet signupTitle={signupTitle} />}
              </Suspense>
            </Box>
          </Stack>
        </ModalBody>
        <ModalCloseButton />
      </ModalContent>
    </Modal>
  );
};

AuthModal.displayName = 'AuthModal';
