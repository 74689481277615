import { AuthModal } from '@kratos/auth';
import {
  useAtreusAuthModalStore,
  useLoginBanner
} from './atreus-auth-modal.store';
import { useCallback } from 'react';

export const AtreusAuthModal = () => {
  const fetchBanner = useAtreusAuthModalStore(s => s.fetchBanner);
  const { imageUrl, link, alt } = useLoginBanner();

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        return;
      }

      fetchBanner();
    },
    [fetchBanner]
  );

  return (
    <AuthModal
      bannerAlt={alt}
      bannerSrc={imageUrl}
      link={link}
      onOpenChange={handleOpenChange}
    />
  );
};
