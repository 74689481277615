import { Box, Container, Icon, Stack, Text } from '@qcx/ui';
import { Link } from '../../Link';
import { LogoLight } from '../LogoLight';
import { PageBellowTheFold } from '../../page/PageBellowTheFold';
import { boards, contests, institutes, items, social, subjects } from './mock';

export const Footer = () => (
  <PageBellowTheFold>
    <Box
      css={{
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '2.5rem'
      }}
      className="dark"
    >
      <Box
        css={{
          bgColor: '#252F3F'
        }}
      >
        <Container
          css={{
            pt: '$10',
            pb: '$6',
            minH: 126,
            d: 'flex',
            flexDirection: 'column',
            '@xl': {
              justifyContent: 'space-between',
              flexDirection: 'column'
            }
          }}
        >
          <Box
            css={{
              '@lg': {
                d: 'flex',
                flexDirection: 'row',
                gap: 50,
                alignItems: 'center'
              }
            }}
          >
            <LogoLight />
            <Box
              css={{
                d: 'grid',
                gap: '$4',
                gridTemplateColumns: 'auto auto',
                '@xl': {
                  gridTemplateColumns: 'auto auto auto auto auto',
                  gap: '$12'
                }
              }}
            >
              {items.map((menu, idx) => (
                <Link
                  key={`menu_${idx}`}
                  css={{ py: '$2', color: '$neutral200' }}
                  title={menu.title}
                  to={menu.to}
                  {...menu.props}
                >
                  <Text variant="md">{menu.title}</Text>
                </Link>
              ))}
            </Box>
          </Box>
          <Box
            as="hr"
            css={{ bgColor: '$neutral700', h: 1, mb: '$8', mt: '$8' }}
          />
          <Stack
            css={{
              justifyContent: 'space-between',
              '@xs': { gap: '$10' },
              '@sm': { gap: '$10' }
            }}
            flow={{ '@initial': 'column', '@lg': 'row' }}
          >
            <Stack
              css={{
                '@xs': { textAlign: 'center' },
                '@sm': { textAlign: 'center' },
                '@lg': { textAlign: 'left' },
                gap: '$2'
              }}
              flow={'column'}
            >
              <Text
                as="h3"
                css={{
                  color: 'white',
                  pb: '$2'
                }}
                variant="sm"
                weight="semibold"
              >
                CONCURSOS
              </Text>
              {contests.map((menu, idx) => (
                <Link
                  key={`menu_${idx}`}
                  css={{
                    color: 'white',
                    '&:hover': {
                      color: '$secondary'
                    }
                  }}
                  title={menu.title}
                  to={menu.to}
                >
                  <Text variant="sm">{menu.title}</Text>
                </Link>
              ))}
            </Stack>
            <Stack
              css={{
                '@xs': { textAlign: 'center' },
                '@sm': { textAlign: 'center' },
                '@lg': { textAlign: 'left' },
                gap: '$2'
              }}
              flow={'column'}
            >
              <Text
                as="h3"
                css={{
                  color: 'white',
                  pb: '$2'
                }}
                variant="sm"
                weight="semibold"
              >
                INSTITUTOS
              </Text>
              {institutes.map((menu, idx) => (
                <Link
                  key={`menu_${idx}`}
                  css={{
                    color: 'white',
                    '&:hover': {
                      color: '$secondary'
                    }
                  }}
                  title={menu.title}
                  to={menu.to}
                >
                  <Text variant="sm">{menu.title}</Text>
                </Link>
              ))}
            </Stack>
            <Stack
              css={{
                '@xs': { textAlign: 'center' },
                '@sm': { textAlign: 'center' },
                '@lg': { textAlign: 'left' },
                gap: '$2'
              }}
              flow={'column'}
            >
              <Text
                as="h3"
                css={{
                  color: 'white',
                  pb: '$2'
                }}
                variant="sm"
                weight="semibold"
              >
                BANCAS
              </Text>
              {boards.map((menu, idx) => (
                <Link
                  key={`menu_${idx}`}
                  css={{
                    color: 'white',
                    '&:hover': {
                      color: '$secondary'
                    }
                  }}
                  aria-label={menu.title}
                  title={menu.title}
                  to={menu.to}
                >
                  <Text variant="sm">{menu.title}</Text>
                </Link>
              ))}
            </Stack>
            <Stack
              css={{
                '@xs': { textAlign: 'center' },
                '@sm': { textAlign: 'center' },
                '@lg': { textAlign: 'left' },
                gap: '$2'
              }}
              flow={'column'}
            >
              <Text
                as="h3"
                css={{
                  color: 'white',
                  pb: '$2'
                }}
                variant="sm"
                weight="semibold"
              >
                DISCIPLINAS
              </Text>
              {subjects.map((menu, idx) => (
                <Link
                  key={`menu_${idx}`}
                  css={{
                    color: 'white',
                    '&:hover': {
                      color: '$secondary'
                    }
                  }}
                  title={menu.title}
                  to={menu.to}
                >
                  <Text variant="sm">{menu.title}</Text>
                </Link>
              ))}
            </Stack>
          </Stack>
          <Box
            as="hr"
            css={{ bgColor: '$neutral700', h: 1, mb: '$8', mt: '$8' }}
          />
          <Box
            css={{
              mb: '$6',
              '@md': { w: '736px' },
              '@lg': { w: 'auto' }
            }}
          >
            <Stack
              css={{
                '@md': { flexCenter: true, gap: '$36' },
                '@lg': { gap: 461 }
              }}
              flow={{ '@initial': 'column', '@md': 'row' }}
            >
              <Box>
                <Text css={{ color: '$neutral400' }}>
                  @ 2023 GRUPOQ S.A. CNPJ: 11.978.743/0001-57
                </Text>
              </Box>
              <Box>
                <Stack
                  css={{
                    '@xs': { mt: '$4', flexDirection: 'column' },
                    '@sm': {
                      flexDirection: 'column-reverse',
                      mt: '-20px'
                    },
                    '@md': {
                      gap: '$4',
                      alignItems: 'center',
                      mt: '$0',
                      flexDirection: 'row'
                    }
                  }}
                >
                  <Stack
                    css={{
                      gap: '$4',
                      '@xs': { mb: '$4' },
                      '@sm': { mt: '-10px' },
                      '@md': { mt: '$0' }
                    }}
                  >
                    <Link
                      target="_blank"
                      title="Termos de uso"
                      to="https://www.qconcursos.com/termos-de-uso"
                    >
                      <Text css={{ color: '$neutral400' }}>Termos de uso</Text>
                    </Link>
                    <Link
                      target="_blank"
                      title="Política de privacidade"
                      to="https://www.qconcursos.com/politica-de-privacidade"
                    >
                      <Text css={{ color: '$neutral400' }}>
                        Política de privacidade
                      </Text>
                    </Link>
                  </Stack>
                  <Stack
                    css={{
                      gap: '$2',
                      zIndex: '$0',
                      '@sm': { justifyContent: 'end' }
                    }}
                    flow="row"
                  >
                    {social.map(item => (
                      <Link
                        key={item.title}
                        css={{
                          size: '40px',
                          borderRadius: '$full',
                          bg: '$neutral700',
                          color: '$white',
                          '&:hover': {
                            bg: `${item.props.bg}`,
                            color: '$white'
                          },
                          '&:visited': {
                            color: '$white'
                          }
                        }}
                        target={item.props.target}
                        title={item.title}
                        to={item.to}
                      >
                        <Icon icon={item.props.icon} size={'lg'} />
                      </Link>
                    ))}
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>
    </Box>
  </PageBellowTheFold>
);
